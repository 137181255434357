import { ActionProps } from '~/editor/history/action.interfaces';

class Action{
  name: string
  undo: () => void
  redo: () => void

  constructor({ name, redo, undo }: ActionProps){
    this.name = name
    this.redo = redo
    this.undo = undo
  }
}

export default Action