import Translation from '~/editor/translation/translation'
import { TranslationVersion } from '~/editor/translation/translation.interfaces'
import { deleteTranslations, insertTranslations, restoreTranslations } from '~/services/database/translations'
import { createSignal, untrack } from 'solid-js'
import { translationsList } from '~/services/current-document/translations-list'
import { history } from '~/editor/history'
import Action from '~/editor/history/action'
import { UpdateTargets } from '~/types/update-targets'
import { HighlightOptions } from '~/types/highlight-options'
import { Comment } from '~/types/comic/comments'

const [translations, setTranslations] = createSignal<Translation[]>([], { equals: false })

const reorderByPosition = () => {
  setTranslations(translations => {
    return translations.sort((a, b) => {
      const aIsHigher = a.group.getY() - b.group.getY() < -32 ? -2 : 0 // aIsHigher has priority so it returns -2
      const aIsLower = a.group.getY() - b.group.getY() > 32 ? 1 : 0
      const aIsLeftmost = a.group.getX() < b.group.getX() ? -1 : 1
      return aIsHigher + aIsLower + aIsLeftmost
    })
  })
  translations().forEach((entry, index) => {
    entry.index = index
    entry.update()
  })
}

const createTranslation = (translation: Translation, updateTargets: UpdateTargets) => {
  if(updateTargets.history){
    history.append(new Action({
      name: 'createTranslation',
      undo: () => deleteTranslation(translation, { canvas: true, database: true, view: true }),
      redo: () => restoreTranslation(translation)
    }))
  }
  appendTranslation(translation)
  highlightTranslation(translation, { scrollView: 'sideview', focusField: true })
  insertTranslations(translation.versions) // TODO: replace with : add to queue
}

const appendTranslation = (translation: Translation) => {
  setTranslations(translations => translations.concat(translation))
  translation.page.document.add(translation.group)
  translation.page.document.add(translation.indexGroup)
  untrack(() => {
    reorderByPosition()
  })
}

const updateTranslation = (id: Translation['id'], values: Partial<TranslationVersion>) => {
  setTranslations(translations => {
    return translations.map(entry => {
      if(entry.id === id){
        const lastVersion = entry.versions[entry.versions.length - 1]
        const isCorrection = entry.page.document.settings.mode === 'proofreading'
        const updatedVersion = {
          ...lastVersion,
          ...values,
          isCorrection,
          date: new Date().toUTCString()
        }
        entry.versions.push(updatedVersion)
        insertTranslations([updatedVersion])
      }
      return entry
    })
  })
  reorderByPosition()
}

const updateTranslationComments = (id: Translation['id'], comments: Comment[]) => {
  setTranslations(translations => {
    return translations.map(entry => {
      if(entry.id === id){
        entry.comments = comments
      }
      return entry
    })
  })
  reorderByPosition()
}

const highlightTranslation = (translation: Translation, options?: HighlightOptions) => {
  const document = translation.page.document
  setTranslations(translations => {
    return translations.map(entry => {
      const shouldUpdate = entry.highlighted !== (entry.id === translation.id)
      if(shouldUpdate){
        entry.highlighted = entry.id === translation.id
        entry.update()
      }
      return entry
    })
  })
  if(options?.scrollView === 'canvas'){
    document.scrollTo(translation.group.top - 64)
  }else if(options?.scrollView === 'sideview'){
    const listElementRef = translationsList()
    const { sideviewElementRef } = translation
    let top = 0
    
    if(listElementRef && sideviewElementRef){
      top = sideviewElementRef.offsetTop - sideviewElementRef.offsetHeight * 1.5
    }
    
    translationsList()?.scrollTo({ top, behavior: 'smooth' })
  }
  if(options?.focusField){
    translation.skipFocusEvent = true
    translation.translatedTextRef?.focus()
  }
  document.render()
}

const unselectTranslation = (translation: Translation) => {
  setTranslations(translations => {
    return translations.map(entry => {
      if (entry.id === translation.id) {
        entry.highlighted = false
      }
      return entry
    })
  })
}

const deleteTranslation = (translation: Translation, updateTargets: UpdateTargets) => {
  if(updateTargets.history){
    history.append(new Action({
      name: 'deleteTranslation',
      undo: () => restoreTranslation(translation),
      redo: () => deleteTranslation(translation, { canvas: true, database: true, view: true })
    }))
  }else{
    translation.createHistoryEntryOnDelete = false
  }
  if(updateTargets.canvas){
    translation.page.document.remove(translation.group)
  }
  setTranslations(translations => translations.filter(entry => entry.id !== translation.id))
  deleteTranslations([translation.id])
  reorderByPosition()
}

const restoreTranslation = (translation: Translation) => {
  translation.createHistoryEntryOnDelete = true
  appendTranslation(translation)
  restoreTranslations([translation.id])
}

const scaleTranslations = () => {
  const translationsList = translations()
  const document = translationsList[0] ? translationsList[0].page.document : null
  translations().forEach(entry => {
    entry.resize()
  })
  document?.render()
}

const resetTranslations = () => {
  translations().forEach(entry => {
    entry.updateDBOnDelete = false
  })
  setTranslations([])
}

const wordCount = (translations: Translation[]) => {
  let originalChars = 0
  let words = 0
  let characters = 0

  translations.forEach(entry => {
    const lastVersion = entry.versions[entry.versions.length - 1]
    originalChars += lastVersion.originalText.length
    words += lastVersion.translatedText.split(' ').length
    characters += lastVersion.translatedText.length
  })

  return {
    original: {
      characters: originalChars
    },
    translation: {
      words,
      characters
    }
  }
}

export {
  reorderByPosition,
  setTranslations,
  createTranslation,
  appendTranslation,
  updateTranslation,
  updateTranslationComments,
  highlightTranslation,
  unselectTranslation,
  deleteTranslation,
  scaleTranslations,
  resetTranslations,
  restoreTranslation,
  wordCount,
  translations
}