import Action from '~/editor/history/action'

class History{
  index: number
  actions: Action[]

  constructor(){
    this.index = -1
    this.actions = []
  }

  undo(){
    if(this.index !== null && this.index >= 0){
      console.log("UNDOING", this.actions[this.index])
      this.actions[this.index].undo()
      this.index--
    }
  }

  redo(){
    if(this.index !== null && this.index + 1 < this.actions.length){
      this.index++
      console.log("REDOING", this.actions[this.index])
      this.actions[this.index].redo()
    }
  }

  append(action: Action){
    // Prune future actions if any, to simplify history
    const hasFutureActions = this.index < this.actions.length - 1
    if (hasFutureActions){
      this.actions = this.actions.slice(0, this.index)
      this.index = this.actions.length - 1
    }
    // Append new action
    this.index++
    this.actions.push(action)
  }

  clear(){
    this.index = -1
    this.actions = []
  }
}

export default History