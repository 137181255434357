import Translation from '~/editor/translation/translation'
import { TranslationVersion } from '~/editor/translation/translation.interfaces'
import { pointToJson } from '~/helpers/database-conversions'
import { chapter } from '~/services/current-document/chapter'
import { reportError } from '~/services/errors'
import { supabase } from '~/services/supabase'
import { Database } from '~/types/supabase'

type ChapterId = Database['public']['Tables']['chapters']['Row']['id']
type TranslationRow = Database['public']['Tables']['translations']['Insert']

const getTranslations = async (chapterId: ChapterId, supabaseClient=supabase) => {
  const { data, error } = await supabaseClient
    .from('translations')
    .select('*')
    .eq('chapter_id', chapterId)
    .eq('deleted', false)
  if (error) {
    reportError(error)
  }
  const translations = data ?? []
  return translations
}

const insertTranslations = async (translations: TranslationVersion[]) => {
  const currentChapterId = chapter()?.id
  if(!currentChapterId){
    throw new Error('chapterId is undefined')
  }
  const rows: TranslationRow[] = translations.map(entry => {
    return {
      created_at: entry.date,
      chapter_id: currentChapterId,
      entry_id: entry.id,
      is_correction: entry.isCorrection,
      is_onomatopoeia: entry.isOnomatopoeia,
      start_point: entry.startPoint ? pointToJson(entry.startPoint) : null,
      end_point: entry.endPoint ? pointToJson(entry.endPoint) : null,
      original_text: entry.originalText,
      translated_text: entry.translatedText,
      page_id: entry.pageId
    }
  })
  const { data, error } = await supabase
    .from('translations')
    .insert(rows)
  if (error) {
    reportError(error)
  }
  translations = data ?? []
  return translations
}

// TODO: Extract this and create a generic "raw insert helper"
const rawInsertTranslations = async (rows: TranslationRow[]) => {
  const { data, error } = await supabase
    .from('translations')
    .insert(rows)
  if (error) {
    reportError(error)
  }
  return data
}

const deleteTranslations = async (translationIds: Translation['id'][]) => {
  const { data, error } = await supabase
    .from('translations')
    .update({
      deleted: true
    })
    .eq('entry_id', translationIds)
  if (error) {
    reportError(error)
  }
  return data
}

const restoreTranslations = async (translationIds: Translation['id'][]) => {
  const { data, error } = await supabase
    .from('translations')
    .update({
      deleted: false
    })
    .eq('entry_id', translationIds)
  if (error) {
    reportError(error)
  }
  return data
}

const resetTranslations = async (chapterId: ChapterId) => {
  const { data, error } = await supabase
    .from('translations')
    .update({
      deleted: true
    })
    .eq('chapter_id', chapterId)
  if (error) {
    reportError(error)
  }
  return data
}

export {
  getTranslations,
  insertTranslations,
  deleteTranslations,
  restoreTranslations,
  resetTranslations,
  rawInsertTranslations
}