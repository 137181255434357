import { createSignal } from 'solid-js'
import { history } from '~/editor/history'
import Action from '~/editor/history/action'
import TypesetText from '~/editor/typeset-text/typeset-text'
import { typeProofreadingList } from '~/services/current-document/type-proofreading-list'
import { deleteTypesetTexts, insertTypesetTexts, restoreTypesetTexts } from '~/services/database/typeset-texts'
import { AppendTypesetTextOptions } from '~/types/editor/typeset-text'
import { HighlightOptions } from '~/types/highlight-options'
import { UpdateTargets } from '~/types/update-targets'

const [typesetTexts, setTypesetTexts] = createSignal<TypesetText[]>([], { equals: false })

const selectedTypesetTexts = () => typesetTexts().filter(entry => entry.selected)

const createTypesetText = (typesetText: TypesetText) => {
  appendTypesetText(typesetText)
  insertTypesetTexts([typesetText]) // TODO: replace with : add to queue
}

const appendTypesetText = (typesetText: TypesetText, options?: AppendTypesetTextOptions) => {
  const document = typesetText.page.document
  document.add(typesetText.textObject)
  typesetText.onFirstDraw()
  if(options?.select){
    typesetText.selected = true
    document.canvas.setActiveObject(typesetText.textObject)
  }
  setTypesetTexts(entries => entries.concat(typesetText))
}

const selectTypesetText = (typesetText: TypesetText) => {
  setTypesetTexts(entries => {
    return entries.map(entry => {
      if (entry.id === typesetText.id) entry.selected = true
      return entry
    })
  })
}

const unselectTypesetText = (typesetText: TypesetText) => {
  setTypesetTexts(entries => {
    return entries.map(entry => {
      if (entry.id === typesetText.id) entry.selected = false
      return entry
    })
  })
}

const updateTypesetText = (typesetText: TypesetText) => {
  setTypesetTexts(entries => {
    return entries.map(entry => {
      if(entry.id == typesetText.id){
        return typesetText
      }else{
        return entry
      }
    })
  })
}

const updateTypesetTextInDB = (typesetText: TypesetText) => {
  const updatedVersion = Object.assign(typesetText, {
    date: new Date().toUTCString()
  })
  
  insertTypesetTexts([updatedVersion])
}

const deleteTypesetText = (typesetText: TypesetText, updateTargets: UpdateTargets) => {
  if(updateTargets.history){
    history.append(new Action({
      name: 'deleteTypesetText',
      undo: () => restoreTypesetText(typesetText),
      redo: () => deleteTypesetText(typesetText, { canvas: true, database: true, view: true })
    }))
  }else{
    typesetText.createHistoryEntryOnDelete = false
  }
  setTypesetTexts(typesetTexts => typesetTexts.filter(entry => entry.id !== typesetText.id))
  if (updateTargets.canvas) {
    typesetText.remove()
  }
  deleteTypesetTexts([typesetText.id])
}

const restoreTypesetText = (typesetText: TypesetText) => {
  typesetText.createHistoryEntryOnDelete = true
  appendTypesetText(typesetText)
  restoreTypesetTexts([typesetText.id])
}

const scaleTypesetTexts = () => {
  typesetTexts().forEach(entry => {
    entry.resize()
  })
}

const highlightTypesetText = (typesetText: TypesetText, options?: HighlightOptions) => {
  const document = typesetText.page.document
  selectTypesetText(typesetText)
  if(options?.scrollView === 'canvas'){
    document.scrollTo(typesetText.textObject.top - 64)
  }else if(options?.scrollView === 'sideview'){
    const listElementRef = typeProofreadingList()
    const { sideviewElementRef } = typesetText
    let top = 0
    
    if(listElementRef && sideviewElementRef){
      top = sideviewElementRef.offsetTop - sideviewElementRef.offsetHeight * 1.5
    }
    typeProofreadingList()?.scrollTo({ top, behavior: 'smooth' })
  }
  if(options?.scrollView === 'canvas' || options?.focusField){
    typesetText.skipSelectionEvent = true
    document.canvas.setActiveObject(typesetText.textObject)
  }
  document.render()
}

export {
  typesetTexts, 
  setTypesetTexts,
  selectedTypesetTexts,
  createTypesetText,
  appendTypesetText,
  updateTypesetText,
  updateTypesetTextInDB,
  selectTypesetText,
  unselectTypesetText,
  scaleTypesetTexts,
  deleteTypesetText,
  highlightTypesetText
}